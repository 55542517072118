<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import  ConfirmActionDialog  from "@/components/ConfirmActionDialog";
import  Lecture  from "@/components/Chapters/Lecture";
import userService from "@/helpers/userService";
import errorHandler from '@/helpers/errorHandler';
import moment from "moment";
import Axios from 'axios';
/**
 * Starter component
 */
export default {
  page: {
    title: "Lectures list",
  },
  head() {
    return {
      title: `Lectures`,
    };
  },
  components: {
    Layout,
    PageHeader,
    ConfirmActionDialog,
    Lecture
  },
  async created() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    await this.$store.dispatch("chapter/getAllChaptersByYear",userService.methods.userYear()).then( (res)=>{
        const Lecture = res.find(x => x.id == this.$route.params.lecture)
        this.showLecture = (Lecture.purchased || Lecture.free);
    }).catch( err => {
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch("video/clearList").then( ()=>{}).catch( err => {
    //   errorHandler.methods.errorMessage(err);
    console.log("No items")
    });
    await this.$store.dispatch("video/getAllVideosByLecture",this.$route.params.lecture).then( ()=>{}).catch( err => {
    //   errorHandler.methods.errorMessage(err);
    console.log("No items")
    });
    await this.$store.dispatch("quiz/getAllQuizesByLecture",{id:this.$route.params.lecture}).then( ()=>{}).catch( err => {
    //   errorHandler.methods.errorMessage(err);
    console.log("No items")
    });
    await this.$store.dispatch("homework/getAllHomeworksByLecture",this.$route.params.lecture).then( ()=>{}).catch( err => {
    //   errorHandler.methods.errorMessage(err);
    console.log("No items")
    });
    await this.$store.dispatch("material/getStudentLectureMaterials",this.$route.params.lecture).then( ()=>{}).catch( err => {
    //   errorHandler.methods.errorMessage(err);
    console.log("No items")
    });
  },
  computed:{
    quizzes(){
        return this.$store.getters['quiz/allQuizes'];
    },
    homeworks(){
        return this.$store.getters['homework/allHomeworks'];
    },
    videos(){
        return this.$store.getters['video/allVideos'];
    },
    materials(){
        return this.$store.getters['material/allMaterials'];
    },
  },
  data: () => ({  
    showLecture: null,
    baseUrl: null,  
    totalRows:0,
    recordId: null,
    title: "Lectures List",
    items: [
      {
        text: "Work Center",
        href: "/"
      },
      {
        text: "Lectures",
        href: "/chapters"
      }
    ],
  }),
  methods:{
    async downloadItem ({ url, label }) {
      await Axios.get(url, { responseType: 'blob',headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`} })
        .then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(err =>{
            errorHandler.methods.errorValue(`Can't download this file now, Please check if you bought this lecture or file is avalible now !`);
        })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="showLecture">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h2 v-if="videos.length">Video</h2>
            <div class="row" v-if="videos.length">
                <router-link v-for="data in videos" :key="data.id" class="col-md-6 col-xl-4" :to="`/students/lectures/${$route.params.lecture}/${data.video_id}/play`">
                    <div class="widget-rounded-circle card shadow-lg">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <div :class=" `avatar-lg rounded-circle bg-icon-${'purple'} `">
                                        <i :class="`${'mdi mdi-video'} font-24 avatar-title text-white`"></i>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="text-right">
                                        <h3 class="text-dark mt-1">
                                            <span>
                                                {{ data.video_name }}
                                            </span>
                                        </h3>
                                        <p class="text-purple mb-1 text-truncate">Watch now</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end card-box -->
                </router-link>
                <!-- end col -->
            </div>
            <!-- end row -->
            <h2 v-if="materials.length">Material</h2>
            <div class="row" v-if="materials.length">
                <div v-for="data in materials" :key="data.id" class="col-md-6 col-xl-4" @click="downloadItem({url:`${baseUrl}material/download?id=${data.id}`,label:data.attach_path})" style="cursor: pointer;">
                    <div class="widget-rounded-circle card shadow-lg">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <div :class=" `avatar-lg rounded-circle bg-icon-${'warning'} `">
                                        <i :class="`${'mdi mdi-file'} font-24 avatar-title text-white`"></i>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="text-right">
                                        <h3 class="text-dark mt-1">
                                            <span>
                                                {{ data.attach_path }}
                                            </span>
                                        </h3>
                                        <p class="text-warning mb-1 text-truncate">Download now</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end card-box -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
            <h2 v-if="quizzes.length">Quizzes & Exams</h2>
            <div class="row" v-if="quizzes.length">
                <router-link v-for="data in quizzes" :key="data.id" class="col-md-6 col-xl-4" :to="`/quizes/${data.id}/takeQuiz`" v-if="data.quiz_type != 'homework'">
                    <div class="widget-rounded-circle card shadow-lg">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <div :class=" `avatar-lg rounded-circle bg-icon-${'success'} `">
                                        <i :class="`${'mdi mdi-star'} font-24 avatar-title text-white`"></i>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="text-right">
                                        <h3 class="text-dark mt-1">
                                            <span>
                                                {{ data.name }}
                                            </span>
                                        </h3>
                                        <p class="text-success mb-1 text-truncate">Start quiz</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end card-box -->
                </router-link>
                <!-- end col -->
            </div>
            <!-- end row -->
            <h2 v-if="homeworks.length">Homeworks</h2>
            <div class="row" v-if="homeworks.length">
                <router-link v-for="data in homeworks" :key="data.id" class="col-md-6 col-xl-4" :to="`/homeworks/${data.id}/takeHomework`">
                    <div class="widget-rounded-circle card shadow-lg">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <div :class=" `avatar-lg rounded-circle bg-icon-${'primary'} `">
                                        <i :class="`${'mdi mdi-book'} font-24 avatar-title text-white`"></i>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="text-right">
                                        <h3 class="text-dark mt-1">
                                            <span>
                                                {{ data.name }}
                                            </span>
                                        </h3>
                                        <p class="text-primary mb-1 text-truncate">Solve homework</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end card-box -->
                </router-link>
                <!-- end col -->
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
