
<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        desc: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: false,
        },
        free: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {};
    },
};
</script>

<template>
<router-link :to="(free)?link:'#'">
    <div class="card">
        <div class="card-body" :style="`background-color:${color};`">
            <div class="d-flex justify-content-between">
                <div>
                    <h5 class="font-weight-900 font-18 mt-0 text-truncate" title="Campaign Sent" style="white-space: pre-line;">
                        <i :class="`${(free)?'fas fa-unlock text-success':'fas fa-lock text-danger'} mr-2`"></i>
                        {{ title.toUpperCase() }}
                    </h5>
                </div>
                <div class="avatar-md">
                    <span class="avatar-title bg-soft-primary rounded">
                        <i :class="`${icon} font-20 text-dark`"></i>
                    </span>
                </div>
            </div>
            <h5 class="my-2 py-1">
                <span>{{ desc }}</span>
            </h5>
        </div>
    </div>
</router-link>
</template>
